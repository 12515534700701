// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

$enable-negative-margins: true;
$offcanvas-horizontal-width: 300px;
$offcanvas-border-width: 0px;
$offcanvas-backdrop-bg: white;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  50: 50px,
  70: 70px,
  100: 100px,
  150: 150px,
  200: 200px,
  280: 280px,
  300: 300px,
  400: 400px,
);

// This is to remove weird blue border on safari after clicks
:focus {
    outline: none;
    outline: none -webkit-focus-ring-color;
}

body {
	min-height: 75rem;
	// padding-top: 4.5rem;
  }

// Fonts
$font-family-sans-serif: "Montserrat", "Helvetica Neue", Arial, sans-serif;
$font-size-base:              1.1rem;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        500;
$font-weight-bold:            700;

// $headings-font-family:        'Permanent Marker', cursive;

// Colors
$primary: #333947;
$secondary: #adafb5;
$warning: #FFC107;
$danger: #DC3545;
$info: #0DCAF0;
$success: #198754;
$dark: #333947;
$light: #F8FBFF;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"warning": $warning,
	"danger": $danger,
	"info": $info,
	"success": $success,
	"dark": $dark,
	"light": $light,
	"green": #329932,
	"red": #FF2800,
	"blue": #0091C2,
	"orange": #E59400,
	"yellow": #ffff32,
);

$ltb-colors: (
	"ltb-light": #f4f4f4,
	"ltb-dark": #e0e0e0,
	"body-dark": #8a9196,
	"body-light": #C1CCD5,
	"twitter": #1da1f2, 
	"whatsapp": #25d366, 
	"googleplus": #ea4335,
	"instagram": #c32aa3, 
	"linkedin": #0a66c2,
	"youtube": #ff0000,
	"facebook": #1877f2,
	"tiktok": #010101,
	"pinterest": #bd081c,
	"snapchat": #fffc00,
	"twitch": #9146ff,
	"discord": #5865f2,
	"Yelp": #d32323,
);

$theme-colors: map-merge($theme-colors, $ltb-colors );

// Tables
$table-hover-bg: #ebf3fa;
$table-border-color: #f0f6fb;
$table-color: $primary;
$table-striped-bg-factor: .03;
$table-striped-bg: rgba($primary, $table-striped-bg-factor);

$mark-bg: #f4f4f4;
$pagination-bg: $primary;
$pagination-focus-bg: #474c59;
$pagination-hover-bg: #474c59;

$input-bg: #ffffff;
$input-color: $primary;
$form-select-color: $primary;
$input-placeholder-color: #848890;
$input-border-color: $primary; //$gray-400
$form-select-border-color: $primary;



.hover-opacity:hover {
	opacity: 0.5;
}

$dropdown-bg: #e0e0e0;
$dropdown-color: $light;
$dropdown-link-color: $primary;
$dropdown-border-color: #f4f4f4;
$dropdown-link-hover-bg: #e0e0e0;
$dropdown-link-hover-color: $primary;
$dropdown-link-active-color: $primary;

$nav-link-hover-color: $primary;
.nav-link:focus, .nav-link:hover {
    color: #3D4850 !important;
}

$navbar-light-color:                $primary;
$navbar-dark-color:                $primary;
$navbar-light-hover-color:          #0091C2;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='primary' class='bi bi-arrow-bar-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='primary' class='bi bi-arrow-bar-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z'/></svg>");


.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 10px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #3D4850;
    z-index: 100;
}

// SMALL VIEW ALERT WINDOW TO COVER ALLMOST ALL VIEW - START
@media only screen and (max-width: 767.98px) {
	.alert-ltb {
		height: 75vh!important;
	}
}
// SMALL VIEW ALERT WINDOW TO COVER ALLMOST ALL VIEW - END

// RESPONSIVE NAVBAR CUSTOMIZATION - START
@media only screen and (max-width: 991.98px) {
	.navbar-ltb {
		background: rgb(244,244,244);
		background: linear-gradient(180deg, rgba(244,244,244,1) 20%, rgba(255,255,255,1) 100%);
		box-shadow: 0 3rem 3rem -2rem rgba(var(--bs-body-color-rgb),.175)!important;
	}
	.dropdown-menu {
		--bs-dropdown-bg: transparent!important;
		--bs-dropdown-border-color: transparent!important;	}
}

.navbar-toggler {
	width: 40px;
	height: 20px;
	position: relative;
	transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
	outline: none !important;
	box-shadow: none !important;
	border: 0 !important;
}

.navbar-toggler span {
	margin: 0;
	padding: 0;
}

.toggler-icon {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: $primary;
	border-radius: 1px;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: .25s ease-in-out
}

.middle-bar {
	margin-top: 0px;
}

// When navigation is clicked
.navbar-toggler .top-bar {
	margin-top: 0px;
	transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
	margin: 0px;
	transform: rotate(-135deg);
}

// State when navbar is collapssed
.navbar-toggler.collapsed .top-bar {
	margin-top:  -20px;
	transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
	margin-top:  20px;
	transform: rotate(0deg);
}
// RESPONSIVE NAVBAR CUSTOMIZATION - END



// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here




@import "../node_modules/bootstrap/scss/bootstrap";

